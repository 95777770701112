import { ChevronIcon, H1, Text, colors } from "@commonsku/styles";
import React from "react";
import styled from "styled-components";
import { skuFontBold } from "../helpers/styles";

const Header = styled.div`
  &&& {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }
`;

const PageHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const TitleText = styled(Text)<{ neutral?: boolean }>`
  font-weight: 600;
  font-size: 24px;
  cursor: default;
  color: ${(props) =>
    props.neutral ? colors.neutrals[100] : colors.primary1[65]};
`;

const OverviewLink = styled.a`
  font-weight: 600;
  font-size: 24px;
  font-family: ${skuFontBold};
  &&& {
    color: ${colors.primary1[65]};
  }
`;

const HeaderText = styled(H1)<{ largeTitle?: boolean }>`
  &&& {
    font-size: ${({ largeTitle }) => (largeTitle ? "32px" : "24px")};
    font-weight: 600;
    line-height: 48px;
    color: ${colors.neutrals[100]};
    margin: 0;
  }
`;

interface SubPageHeaderProps {
  title: string;
  largeTitle?: boolean;
  children?: React.ReactNode;
  clientName?: string;
  className?: string;
}

export const SubPageHeader = ({
  title,
  children,
  largeTitle,
  clientName,
  className = undefined,
}: SubPageHeaderProps) => {
  return (
    <div className={className}>
      {clientName === undefined ? (
        <Header>
          <HeaderText largeTitle={largeTitle}>{title}</HeaderText>
          {children}
        </Header>
      ) : (
        <PageHeader>
          <OverviewLink href={`./`} rel="opener noreferrer" target="_blank">
            {clientName}
          </OverviewLink>
          <ChevronIcon size="large" color={colors.neutrals[60]} />
          <TitleText neutral>{title}</TitleText>
          {children}
        </PageHeader>
      )}
    </div>
  );
};
