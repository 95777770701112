import React, { useEffect } from 'react';
import { Row, Col, LabeledInput, AddressAutocompleteInput } from '@commonsku/styles';
import { useForm } from '../../hooks';
import { LabeledSelect, Alert } from '../helpers';
import { getAddressTypeOptions, getCountryOptions, getStateOptions } from '../company-search/selectors';
import config from '../../config';

const CreateAddressForm = React.forwardRef(({
    parent_id,
    parent_type = 'CLIENT',
    address_type = 'BILLING',
    showError=false,
    setFormValid,
    setForm,
    addressToUpdate=null,
    ...props
}, ref) => {
    const address_types = getAddressTypeOptions();
    const address_countries = getCountryOptions();

    const { form, handleChange, handleSelectChange, setInForm } = useForm({
        parent_type: parent_type,
        parent_id: parent_id,
        address_type: addressToUpdate ? addressToUpdate.type : address_type,
        address_name: addressToUpdate ? addressToUpdate.name : '',
        address_company: addressToUpdate ? addressToUpdate.company: '',
        address_line_1: addressToUpdate ? addressToUpdate.address_line_1 : '',
        address_line_2: addressToUpdate ? addressToUpdate.address_line_2 : '',
        address_line_3: addressToUpdate ? addressToUpdate.address_line_3 : '',
        address_line_4: addressToUpdate ? addressToUpdate.address_line_4 : '',
        address_country: addressToUpdate ? addressToUpdate.country : '',
        address_state: addressToUpdate ? addressToUpdate.state : '',
        address_city: addressToUpdate ? addressToUpdate.city : '',
        address_postal: addressToUpdate ? addressToUpdate.postal : '',
    });

    const address_state_options = React.useMemo(() => {
        if (form.address_country && form.address_country.length > 0) {
            return getStateOptions(form.address_country);
        }
        return [];
    }, [form.address_country]);

    const isFormValid = React.useMemo(() => {
        return form.address_name.length > 0
            && (form.address_company.length > 0 || form.address_type === "SHIPPING")
            && form.address_country.length > 0;
    }, [form]);

    useEffect(() => {
        setFormValid && setFormValid(isFormValid);
    }, [isFormValid, setFormValid]);

    useEffect(() => {
        setForm && setForm({
            ...form,
            address_name: `${form.address_name}`.trim(),
            address_company: `${form.address_company}`.trim(),
            address_line_1: `${form.address_line_1}`.trim(),
            address_line_2: `${form.address_line_2}`.trim(),
            address_line_3: `${form.address_line_3}`.trim(),
            address_line_4: `${form.address_line_4}`.trim(),
            address_country: `${form.address_country}`.trim(),
            address_state: `${form.address_state}`.trim(),
            address_city: `${form.address_city}`.trim(),
            address_postal: `${form.address_postal}`.trim(),
        });
    }, [form, setForm]);

    const renderAlert = () => {
        if (showError && !isFormValid) {
            return <Alert type="danger">Please complete all required fields!</Alert>;
        }
        if (showError && isFormValid) {
            <Alert type="success">Looks good!</Alert>;
        }
        return null;
    };

    return (
        <div ref={ref}>
            <Row style={{paddingTop: 10, marginTop: showError ? 40 : undefined}}>
                {renderAlert()}

                <Col xs md={6} padded>
                    <LabeledInput required
                        label='Address Name'
                        name='address_name'
                        error={showError && form.address_name === ''}
                        value={form.address_name}
                        onChange={handleChange}
                    />
                </Col>
                <Col xs md={6} padded>
                    <LabeledInput
                        required={form.address_type !== "SHIPPING"}
                        label='Company Name'
                        name='address_company'
                        error={showError && form.address_company === '' && form.address_type !== "SHIPPING"}
                        value={form.address_company}
                        onChange={handleChange}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs md={6} padded>
                    <AddressAutocompleteInput
                        apiKey={config.googleMapsApiKey}
                        label='Address 1'
                        name='address_line_1'
                        value={form.address_line_1}
                        country={config.addressAutocompleteCountries}
                        optionsListStyle={{ marginTop: '68px' }}
                        onInputChange={v => {
                            setInForm('address_line_1', v);
                        }}
                        onChange={v => {
                            const addr = v.parsed_address;
                            setInForm('address_line_1', addr.address_line_1);
                            setInForm('address_line_2', addr.address_line_2);
                            setInForm('address_line_3', addr.address_line_3);
                            setInForm('address_country', addr.address_country);
                            setInForm('address_state', addr.address_state);
                            setInForm('address_city', addr.address_city);
                            setInForm('address_postal', addr.address_postal);
                        }}
                    />
                </Col>
                <Col xs md={6} padded>
                    <LabeledInput
                        label='Address 2'
                        name='address_line_2'
                        value={form.address_line_2}
                        onChange={handleChange}
                    />
                </Col>
                <Col xs md={6} padded>
                    <LabeledInput
                        label='Address 3'
                        name='address_line_3'
                        value={form.address_line_3}
                        onChange={handleChange}
                    />
                </Col>
                <Col xs md={6} padded>
                    <LabeledInput
                        label='Address 4'
                        name='address_line_4'
                        value={form.address_line_4}
                        onChange={handleChange}
                    />
                </Col>

                <Col xs md={6} padded>
                    <LabeledSelect required
                        label='Country'
                        name='address_country'
                        error={showError && (form.address_country === '' || form.address_country === null)}
                        value={form.address_country}
                        options={address_countries}
                        onChange={handleSelectChange('address_country')}
                        inPopup
                    />
                </Col>
                <Col xs md={6} padded>
                    <LabeledSelect
                        label='State'
                        name='address_state'
                        value={form.address_state}
                        options={address_state_options}
                        onChange={handleSelectChange('address_state')}
                        inPopup
                        isClearable
                    />
                </Col>
                <Col xs md={6} padded>
                    <LabeledInput
                        label='City'
                        name='address_city'
                        value={form.address_city}
                        onChange={handleChange}
                    />
                </Col>
                <Col xs md={6} padded>
                    <LabeledInput
                        label='Postal / Zipcode'
                        name='address_postal'
                        value={form.address_postal}
                        onChange={handleChange}
                    />
                </Col>
                <Col xs md={6} padded>
                    <LabeledSelect
                        label='Address Type'
                        name='address_type'
                        value={form.address_type}
                        options={address_types}
                        onChange={handleSelectChange('address_type')}
                        inPopup
                    />
                </Col>
            </Row>
        </div>
    );
});

export default CreateAddressForm;
