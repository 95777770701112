import { filter } from 'lodash';
import { AlertIcon, Button, Col, colors, Img, Popup, Row, Text, themeOptions, XIcon } from "@commonsku/styles"
import PS from './PromostandardsLabel';
import styled from "styled-components";
import { getAbsoluteUrl } from '../utils';
import { useNavigate } from 'react-router-dom';

const PopUpHeader = styled.div`
  &&& {
    font-size: 24px;
    font-weight: 600;
    line-height: 40px;
    color: ${colors.neutrals[90]};
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const WarningMessage = styled.div`
  &&& {
    display: flex;
    padding: 16px;
    border-radius: 5px;
    gap: 16px;
    ${props => props.color && `color: ${props.color};`}
    ${props => props.backgroundColor && `background-color: ${props.backgroundColor};`}
  }
`;

const InventoryWarningPopup = ({
  purchaseOrder,
  insufficientItems,
  onClose,
  handleResendEPO,
  hasError,
  checkInventory
}) => {
  const navigate = useNavigate();

  const header = () => {
    return <PopUpHeader>
      <div>
        <PS iconColor={colors.neutrals.darkest} style={{ lineHeight: 0 }} />
        {hasError ? 'Inventory Check Failed' : 'Inventory Warning'}
      </div>
      <XIcon size="large" pointer onClick={onClose} />
    </PopUpHeader>
  }

  const linkToSalesOrder = () => {
    navigate(`/project/${purchaseOrder.order.job_number}/${purchaseOrder.order.order_type.replace(' ', '-').toLowerCase()}/${purchaseOrder.order.form_number}`);
  }

  const handleSendEpo = () => {
    onClose();
    handleResendEPO();
  }

  const handleTryAgain = () => {
    onClose();
    checkInventory();
  }

  const renderActions = () => {
    return <div style={{ display: 'flex', overflow: 'hidden', alignItems: 'end ', flexGrow: 1, justifyContent: 'end', gap: 8, minHeight: 40 }}>
      <Button size='medium' variant="secondary" onClick={linkToSalesOrder} >Edit Sales Order</Button>
      <Button size='medium' onClick={handleSendEpo}>Submit Anyway</Button>
    </div>
  }

  const renderErrorActions = () => {
    return <div style={{ display: 'flex', overflow: 'hidden', flexGrow: 1, justifyContent: 'center', gap: 16, paddingTop: 26, paddingBottom: 36 }}>
      <Button size='huge' variant="secondary" onClick={handleTryAgain} >Try Again</Button>
      <Button size='huge' onClick={handleSendEpo}>Submit Without Checking</Button>
    </div>
  }

  const renderItem = (item, index) => {
    const totalCols = 10;
    const skus = insufficientItems[item.item_id];
    return (
      <Row key={index} style={{ width: '100%', borderBottom: `1px solid ${colors.neutrals[40]}`, paddingBottom: 10 }}>
        <Col xs={1} totalCols={totalCols}>
          <Img src={getAbsoluteUrl(item.product_img)} />
        </Col>
        <Col xs={4} totalCols={totalCols} style={{ display: 'flex', flexDirection: 'row', gap: 4, paddingLeft: 10 }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 4, marginLeft: 40 }}>
            <Text style={{ fontFamily: themeOptions.fontFamilies.skuFontBold, color: colors.neutrals.darkest }}  >{item.item_name}</Text>
            <Text style={{ fontFamily: themeOptions.fontFamilies.regular, color: colors.neutrals.darkest }}>{purchaseOrder.division_name}</Text>
            <Text style={{ fontFamily: themeOptions.fontFamilies.regular, color: colors.neutrals.darkest }}>{item.item_code}</Text>
          </div>
        </Col>
        <Col style={{ display: 'flex', flexDirection: 'column', gap: 8 }} key={index} xs={5} totalCols={totalCols}>
          {skus.map((sku, index) =>
            <Row key={index} style={{ paddingBottom: 10 }}>
              <Col xs={2} totalCols={5}>
                <Text style={{ fontFamily: themeOptions.fontFamilies.regular, color: colors.neutrals.darkest }}>{sku.sku_description ?? (sku.color && sku.size ? `${sku.color} / ${sku.size}` : sku.color || sku.size ? sku.color || sku.size : 'TDB')}</Text>
              </Col>
              <Col xs={3} totalCols={5} style={{ display: 'inline-flex', flexDirection: 'row', gap: 16 }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', minWidth: 75, backgroundColor: colors.errors[10], color: colors.errors[70], borderRadius: 500, paddingRight: 5 }}>
                  <AlertIcon style={{ minWidth: 24 }} />
                  <div>{parseInt(sku.quantity, 10)}</div>
                </div>
                <Text style={{ fontFamily: themeOptions.fontFamilies.regular, color: colors.errors[60] }}>{sku.quantity_available ? `Only ${sku.quantity_available} available` : 'Not available'}</Text>
              </Col>
            </Row>
          )}
        </Col >
      </Row >
    );
  }

  const renderInsufficientItems = (items) => {
    return <div style={{ display: 'flex', flexDirection: 'column', gap: 10, overflowY: 'auto', height: '100%' }}>
      {items.map((item, index) => renderItem(item, index))}
    </div>
  }

  const renderBody = () => {
    const filteredItems = filter(purchaseOrder.items, (item) => insufficientItems[item.item_id]);
    return <>
      <WarningMessage color={colors.errors[80]} backgroundColor={colors.errors[10]}>
        <AlertIcon style={{ minWidth: 24 }} />
        <div>Some of the items on this PO are out of stock. Please make sure that none of the items exceed availability.</div>
      </WarningMessage>
      {renderInsufficientItems(filteredItems)}
      {renderActions()}
    </>
  }

  const renderErrorBody = () => {
    return <>
      <WarningMessage color={colors.errors[80]} backgroundColor={colors.errors[10]}>
        <AlertIcon style={{ minWidth: 24 }} />
        <div>Inventory check failed due to an external error. Please try again later.</div>
      </WarningMessage>
      {renderErrorActions()}
    </>
  }

  return <Popup
    header={header()}
    width={hasError ? "576px" : "1079px"} height={hasError ? "302px" : "619px"}
    style={{ padding: 16, gap: 16, borderRadius: 10, display: 'flex', flexDirection: 'column' }}
    popupContentStyle={{ display: 'flex', flexDirection: 'column', gap: 16 }}
  >
    {hasError ? renderErrorBody() : renderBody()}
  </Popup>
};

export default InventoryWarningPopup;
