import {
    createEntityAdapter, createSlice
} from '@reduxjs/toolkit';
import { oauth } from '../utils';

interface ContactImportHubspotJob {
    contact_import_hubspot_job_id: string;
    date_created: string;
    date_completed: string;
}
/**
 * The entity adapter
 */
const adapter = createEntityAdapter({
    selectId: (item: ContactImportHubspotJob) => item.contact_import_hubspot_job_id,
    sortComparer: (a, b) => {
        return Date.parse(b.date_created) - Date.parse(a.date_created);
    }
});

const slice = createSlice({
    name: 'contact_import_hubspot_jobs',
    initialState: adapter.getInitialState({
        loading: 'idle'
    }),
    reducers: {
        loading(state, action) {
            if (state.loading === 'idle') {
                state.loading = 'pending';
            }
        },
        loaded(state, action) {
            if (state.loading === 'pending') {
                state.loading = 'idle';
            }
        },
        received(state, action) {
            if (state.loading === 'pending') {
                adapter.setAll(state, action.payload);
                state.loading = 'idle';
            }
        },
    }
});

export const {
    loading,
    loaded,
    received,
} = slice.actions;

const baseUrl = 'contact-import-hubspot-jobs';

/**
 * The actions (thunk)
 */
export const getAll = () => async (dispatch, getState) => {
    const state = getState();
    dispatch(loading(state));
    const res = await oauth('GET', baseUrl, {});
    dispatch(received(res.json.jobs));
    dispatch(loaded(state));
    return res.json;
};

export const addItem = () => async (dispatch, getState) => {
    const state = getState();
    dispatch(loading(state));
    const res = await oauth('POST', baseUrl, {});
    dispatch(loaded(state));
    return res.json;
};

/**
 * The selectors
 */
export const selectors = adapter.getSelectors();

export default slice.reducer;

