import _ from 'lodash';
import React, { useCallback, useEffect, useId, useState, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { AsyncSelect, LabeledAsyncSelect,  } from '@commonsku/styles';
import { WindowedMenuList } from '../helpers';
import { oauth } from '../../utils';
import { getClientOptions as baseGetClientOptions } from '../../selectors/dropdowns';
import { createFinalizeLoadClientList } from '../../actions/client';

const getClientOptions = createSelector(
    baseGetClientOptions,
    s => s.map(v => ({ ...v, value: v.key, label: v.value })),
);

const AsyncClientSelect = (
    {
        label,
        name,
        value,
        onChange,
        autoFocus=false,
        openMenuOnFocus=false,
        defaultMenuIsOpen=false,
        onClickNewClient=null,
        maxAvailableHeight=null,
        labelStyle={},
        isDisabled=false,
    }
) => {
    const dispatch = useDispatch();
    const client_options = useSelector(getClientOptions);
    const findOption = useCallback(
        (clientId) => _.find(client_options, v => v.value === clientId),
        [client_options]
    );

    const [selectedClient, setSelectedClient] = useState(findOption(value));

    // 40px add new client, 38px search box, 2px menu list border
    const maxMenuHeight = maxAvailableHeight ? maxAvailableHeight - 40 - 38 - 2 : undefined;

    const SelectMenuList = React.useCallback((p) => {
        return (
            <>
                <WindowedMenuList {...p} height={55} />
                {onClickNewClient && <div
                    onClick={() => {
                        onClickNewClient(1);
                    }}
                    style={{
                        cursor: 'pointer',
                        paddingTop: '8px',
                        paddingBottom: '8px',
                        background: '#E1F7FA',
                        color: '#00A0B6',
                        bottom: 0,
                        position: 'sticky',
                        textAlign: 'center',
                        marginBottom: 0,
                        fontWeight: 'bold',
                    }}
                >+ New Client</div>}
            </>
        );
    }, [onClickNewClient]);

    const loadClients = useCallback((searchTerm, callback) => {
        const search = _.debounce(async (client_name) => {
            if (!client_name || (client_name || '').length < 2) {
                return [];
            }

            const { json } = await oauth('GET', 'company', {
                company_type: 'CLIENT',
                search: true,
                include_inactive: false,
                'max-results': 20,
                company_name: client_name.toLowerCase().trim(),
                order_by: 'latest_use',
                order_dir: 'DESC',
            });

            const options = json.companies.map(
                c => ({
                    ...c,
                    label: `${c.client_name}`.trim(),
                    value: c.client_id,
                })
            );
            if (callback) {
                callback(options);
            }
            dispatch(createFinalizeLoadClientList(json.companies));
        }, 400);
        search(searchTerm);
    }, []);

    let Select = AsyncSelect;
    if (label) {
        Select = LabeledAsyncSelect;
    }

    useEffect(() => {
        setSelectedClient(findOption(value));
    }, [value, findOption]);

    return (
        <Select
            inPopup
            autoFocus={autoFocus || defaultMenuIsOpen}
            openMenuOnFocus={openMenuOnFocus || defaultMenuIsOpen}
            defaultMenuIsOpen={defaultMenuIsOpen}
            maxMenuHeight={maxMenuHeight}
            onChange={e => {
                setSelectedClient(e);
                onChange(e);
            }}
            value={selectedClient}
            placeholder="Select Client..."
            label={label}
            labelStyle={!!label ? labelStyle : undefined}
            name={name}
            components={{ MenuList: SelectMenuList }}
            loadOptions={loadClients}
            cacheOptions
            defaultOptions={client_options}
            isDisabled={isDisabled}
        />
    );
};

export default AsyncClientSelect;
