import { useDispatch } from 'react-redux';
import React, { useState } from 'react';
import { Button, Popup, AlertIcon, LabeledInput, Row, IconButton, XIcon } from '@commonsku/styles';
import { createAddItemLocation } from '../actions';

const AddNewPSLocationPopup = ({ item_id , onClose }) => {
  const dispatch = useDispatch();
  const [locationName, setLocationName] = useState('');

  return(
    <Popup
      width="46%" height="auto" title="Add New Location" onClose={onClose}
      controls={<IconButton
        Icon={XIcon}
        variant="text" mr={24} mb={24}
        style={{marginBottom:'0px', marginRight:'0px', padding:'0px'}}
        onClick={onClose}
      />}
    >
      <div style={{marginTop: '15px',marginBottom: '15px'}}>
        <LabeledInput
          label="Location Name"
          value={locationName}
          onChange={(e) => {
            setLocationName(e.target.value);
          }}
          autoFocus={true}
        />
      </div>
      <div style={{ background:'#FFF2F3', color:'#B4000C', marginBottom: '16px' }}>
        <div style={{ display: 'flex', padding: '16px' }}>
          <div style={{ display: 'inline-block' }}>
            <AlertIcon />
          </div>
          <div style={{ display: 'inline-block', paddingLeft: '16px' }}>
            <span style={{ display: 'block' }}>
              If you add a location that’s not offered by supplier, you will not be able to use an ePO and your PO will be submitted by email instead.
             <br/> <br/> Any existing decoration will be removed.
            </span>
          </div>
        </div>
      </div>
      <Row style={{gap:'16px', float: 'inline-end'}}>
        <Button variant="secondary" size="medium" onClick={e => {
          e.preventDefault();
          onClose();
        }}>Cancel</Button>
        <Button variant="primary" size="medium" onClick={e => {
          e.preventDefault();
          dispatch(createAddItemLocation(item_id, null, null, null, null, locationName)).then(() => {
            onClose();
          });
        }} disabled={!locationName}
        >Add New Location</Button>
      </Row>
    </Popup>
  );
};

export default AddNewPSLocationPopup;
