import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Select from './Select';
import MentionTextarea from './MentionTextarea';
import { getUserOptions } from '../selectors/dropdowns';
import { createAddMessage } from '../actions/message';
import { createDeleteTemp, createAddTemp } from '../actions/temp';
import { createSelectFilePopup } from '../actions/popup';
import ReactTooltip from 'react-tooltip';

import DateInput from './DateInput';
import { dateStr } from '../utils';

const TEMP_FILE_REGISTER = 'message_file_id';

const ProductBtn = ({ job_number, disabled }) => {
  const navigate = useNavigate();
  const onAddProductToFeed = (job_number) => {
    navigate('/project/' + job_number + '/add-product');
  };

  return (
    <a className="button"
      style={{ background: '#02c0da' }}
      onClick={e => {e.preventDefault(); onAddProductToFeed(job_number);}}
      disabled={disabled}
    ><i className="fi-shopping-bag">&nbsp;&nbsp;</i> Product</a>
  );
};

class Publisher extends Component {

  constructor(props) {
    super(props);

    this.state = {
      files: props.files,
      show_reminder: false,
      reminder_date: '',
      reminder_user_id: props.default_reminder_user_id,
      publisher_text_value: this.props.message ? this.props.message : '',
      products: this.props.products
    };

    this.handleAddMessage = this.handleAddMessage.bind(this);
    this.handleToggleReminder = this.handleToggleReminder.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleChangeReminderUser = this.handleChangeReminderUser.bind(this);
    this.handlePublisherText = this.handlePublisherText.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ products: nextProps.products });
    if(nextProps.files.length) {
      this.setState({ files: nextProps.files });
    }
  }

  componentWillUnmount(){
    this.props.onCreateAddTemp('message', this.state.publisher_text_value);
    this.props.onCreateDeleteTemp(TEMP_FILE_REGISTER);
  }

  removeNoneUTF8mb3(str) {
    return str.replace(/([\uD800-\uDBFF][\uDC00-\uDFFF])/g, '');
  }

  handleAddMessage(job_id, publisher_text, publisher_reminder_user_id, publisher_reminder_date, files, default_reminder_user_id, products) {
    let patt = /(@[A-z0-9]+)/g;
    let user_ids_arr = [];
    let m;
    do {
      m = patt.exec(publisher_text);
      if(m && this.props.mention_users.filter(u => u.mask == m[0].slice(1)).length != 0) {
        user_ids_arr.push(this.props.mention_users.filter(u => u.mask == m[0].slice(1))[0]['user_id']);
      }
    } while(m);
    let tagged_users = user_ids_arr.toString();
    let product_ids = Object.values(products).map(p => p.product_id);
    const file_ids = files.map(f => f.file_id);
    this.props.onAddMessage(job_id, this.removeNoneUTF8mb3(publisher_text), publisher_reminder_user_id, publisher_reminder_date, file_ids, tagged_users, null, null, product_ids);
    this.props.onCreateDeleteTemp(TEMP_FILE_REGISTER);
    this.setState({
      files: [],
      show_reminder: false,
      reminder_user_id: this.props.default_reminder_user_id,
      reminder_date: '',
      publisher_text_value: ''
    });
  }

  handleToggleReminder() {
    this.setState({ show_reminder: !this.state.show_reminder });
  }

  handleChangeDate(value) {
    this.setState({ reminder_date: value });
  }

  handleChangeReminderUser(e) {
    this.setState({ reminder_user_id: e});
  }

  handlePublisherText(value) {
    this.setState({ publisher_text_value: value });
  }

  render() {
    const {
      job_id,
      client_id,
      users,
      mention_users,
      default_reminder_user_id,
      //loading,
      job_number,
      onCreateDeleteTemp,
      onCreateSelectFilePopup
    } = this.props;
    const {
      files, reminder_date, reminder_user_id,
    } = this.state;
    const products = Object.values(this.state.products);
    const text_value = this.state.publisher_text_value;
    const hideReminder = {
      'display' : 'none'
    };
    return (
      <div className="publisher">
        <ReactTooltip id="commentTip" place="top" className="customTip">Comment</ReactTooltip>
        <ReactTooltip id ="pinTip" place="top"  className="customTip" />
        <ReactTooltip id="deleteTip" place="top"  className="customTip">Delete</ReactTooltip>
        <ReactTooltip id="editTaskTip" place="top"  className="customTip">Edit</ReactTooltip>
        <div className="row no-maxWidth">
          <div className="medium-12 columns">
            <MentionTextarea placeholder="Post a note about this project"
              value={text_value} mention_users={mention_users}
              onChange={this.handlePublisherText}/>
            <div className="medium-8 columns">
              {files[0] ?
                `${files[0].file_display_name} is selected.`
              : null}
            </div>
            <div className="medium-4 columns">
            </div>
            {products.map((p, idx) =>
              <div key={p.product_id} className={'small-4 medium-2 columns' + (idx === products.length - 1 ? ' end' : '')} onClick={e => {e.preventDefault(); onCreateDeleteTemp(`product.${p.product_id}`); }}>
                <img src={p.product_img_small.substring(0, 4) == 'http' ? p.product_img_small : `/${p.product_img_small}`} style={{'margin' : '5px'}} />
              </div>
            )}
          </div>
          <div className="large-3 columns">
            <a className="button" style={{ background: '#02c0da' }} onClick={e => {e.preventDefault(); this.handleToggleReminder();}} disabled={products.length}><i className="fi-clock">&nbsp;&nbsp;</i> Task</a>
          </div>
          <div className="large-3 columns">
            <ProductBtn job_number={job_number} disabled={this.state.files.length || this.state.show_reminder} />
          </div>
          <div className="large-3 columns">
            <a className="button" style={{ background: '#02c0da' }} onClick={e => {e.preventDefault(); onCreateSelectFilePopup(TEMP_FILE_REGISTER, job_id, 'JOB', client_id);}}>
              <i className="fi-paperclip">&nbsp;&nbsp;</i> File
            </a>
          </div>
          <div className="large-3 columns">
            <a className="button alt" onClick={e => {
              e.preventDefault();
              this.handleAddMessage(
                job_id, text_value, reminder_user_id, reminder_date,
                files, default_reminder_user_id, this.state.products
              );
            }} disabled={text_value.trim() != '' ? false : true}>Post</a>
          </div>
        </div>
        <div className="row" style={this.state.show_reminder ? null : hideReminder }>
          <div className="medium-6 columns">
            <label>Task date</label>
            <DateInput value={this.state.reminder_date} onChange={this.handleChangeDate} />
          </div>
          <div className="medium-6 columns">
            <label>Task For</label>
            <Select ref="publisher_reminder_user_id" value={reminder_user_id} options={users} change={this.handleChangeReminderUser} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    default_reminder_user_id: state.identity.user_id,
    users: getUserOptions(state),
    mention_users: Object.values(state.entities.users).filter(u => u.mask != false),
    job_number: state.entities.projects[Object.keys(state.entities.projects)[0]].job_number,
    products: Object.keys(state.temp).filter(k => /product\..+/.test(k)).reduce((o, k) => { o[k] = state.temp[k]; return o; }, {}),
    message: state.temp.message,
    files: state.temp[TEMP_FILE_REGISTER] ? [state.entities.files[state.temp[TEMP_FILE_REGISTER]]] : []
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAddMessage: (job_id, text, reminder_user_id, reminder_date, files, tagged_users, details_parent_id, details_parent_type, product_ids) => {
      dispatch(createAddMessage(job_id, text, reminder_user_id, reminder_date, files, tagged_users, details_parent_id, details_parent_type, product_ids));
      dispatch(createDeleteTemp(/product\..+/));
    },
    onCreateDeleteTemp: key => {
      dispatch(createDeleteTemp(key));
    },
    onCreateAddTemp: (key, value) => {
      dispatch(createAddTemp(key, value));
    },
    onCreateSelectFilePopup: (register, parent_id, parent_type, client_id) => {
      dispatch(createSelectFilePopup(register, parent_id, parent_type, client_id));
    }
  };
};

const ConnectedPublisher = connect(mapStateToProps, mapDispatchToProps)(Publisher);

export default ConnectedPublisher;
