import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    Text, Button, Col, Row, LabeledInput,
    ArrowIcon,
    OpportunityCircleIcon,
    PresentationCircleIcon,
    EstimateCircleIcon,
    SalesOrderCircleIcon,
    colors,
    IconButton,
} from '@commonsku/styles';
import { LabeledSelect } from '../helpers';
import { eventTypeOptions } from '../../selectors/event_types';
import { supplierOptions } from '../../selectors/suppliers';
import AsyncClientSelect from './AsyncClientSelect';

export default function NewProjectOrderForm({
    onContinue,
    onCreateClient,
    form,
    updateField,
    onClickNewClient,
    isDisabled,
    ...props
}) {
    const event_type_options = useSelector(eventTypeOptions);
    const supplier_options = useSelector(supplierOptions);

    const canContinue = React.useMemo(() => {
        return form.client_id
            && form.job_name
            && form.event_type_id
            && form.stage;
    }, [form]);

    function handleContinue() {
        if (!canContinue) { return; }
        onContinue(form);
    }
    const onChangeClient = React.useCallback(e => updateField('client_id', e?.value ?? ''), [updateField]);

    return (
        <Row>
            <Col xs padded>
                <AsyncClientSelect
                    onClickNewClient={onClickNewClient}
                    value={form.client_id}
                    onChange={onChangeClient}
                    label="Client Name"
                    name="client_id"
                    isDisabled={isDisabled}
                />
            </Col>
            <Col xs padded>
                <LabeledInput
                    required
                    name="job_name"
                    label="Project Name"
                    value={form.job_name}
                    onChange={e => updateField('job_name', e.target.value)}
                    autoComplete="off"
                />
            </Col>
            <Col xs padded>
                <LabeledSelect
                    inPopup
                    required
                    name="event_type"
                    label="Event Type"
                    options={event_type_options}
                    value={form.event_type_id}
                    onChange={e => updateField('event_type_id', e.value)}
                />
            </Col>
            <Col xs padded>
                <Text as="p" style={{ fontWeight: 500 }}>Starting Stage</Text>
                <Row>
                    <Col xs sm={6} md={3} lg={3}>
                        <OpportunityCircleIcon
                            onClick={() => updateField('stage', 'OPPORTUNITY')}
                            variant={'primary'}
                            selected={form.stage === 'OPPORTUNITY'}
                            style={{ cursor: 'pointer' }}
                        />
                    </Col>
                    <Col xs sm={6} md={3} lg={3}>
                        <PresentationCircleIcon
                            onClick={() => updateField('stage', 'PRESENTATION')}
                            variant={'primary'}
                            selected={form.stage === 'PRESENTATION'}
                            style={{ cursor: 'pointer' }}
                        />
                    </Col>
                    <Col xs sm={6} md={3} lg={3}>
                        <EstimateCircleIcon
                            onClick={() => updateField('stage', 'ESTIMATE')}
                            variant={'primary'}
                            selected={form.stage === 'ESTIMATE'}
                            style={{ cursor: 'pointer' }}
                        />
                    </Col>
                    <Col xs sm={6} md={3} lg={3}>
                        <SalesOrderCircleIcon
                            onClick={() => updateField('stage', 'SALES ORDER')}
                            variant={'primary'}
                            selected={form.stage === 'SALES ORDER'}
                            style={{ cursor: 'pointer' }}
                        />
                    </Col>
                </Row>
            </Col>
            {/* <Col xs padded>
                <LabeledCheckbox
                    value={form.collaborate_suppliers}
                    checked={form.collaborate_suppliers}
                    onChange={v => setForm(
                        s => ({...s, collaborate_suppliers: !s.collaborate_suppliers})
                    )}
                    label="Collaborate with commonsku suppliers"
                />
            </Col> */}

            {/* {form.collaborate_suppliers ? <>
                <Col xs>
                    <Select
                        inPopup
                        options={supplier_options}
                        value={form.collaborate_supplier_id}
                        onChange={e => onChangeField('collaborate_supplier_id', e.value)}
                    />
                </Col>
                <Col xs>
                    <Textarea
                        value={form.collaborate_supplier_message}
                        onChange={e => onChangeField('collaborate_supplier_message', e.target.value)}
                        placeholder="Share some details about your  promotion with suppliers"
                    />
                </Col>
            </> : null} */}

            <Col padded xs pb={20} style={{ textAlign: "center" }}>
                <IconButton
                    style={{ width: '100%' }}
                    variant={canContinue ? "primary" : "disabled"}
                    onClick={handleContinue}
                    Icon={ArrowIcon}
                    iconPosition='right'
                    iconProps={{direction: 'right'}}
                >
                    Continue
                </IconButton>
            </Col>
        </Row>
    );
}
