/**
 * common(sku) styles
 */

const commonFontFamily = [
    '"museo-sans"',
    '"Helvetica Neue"',
    'Helvetica',
    'Roboto',
    'Arial',
    'sans-serif',
];
export const skuFont = "'skufont-regular'";
export const skuFontBold = "'skufont-demibold'";
export const skuFontMedium = "'skufont-medium'";

export const skuFontFamily = [skuFont].concat(commonFontFamily).join(',');
export const skuFontFamilyBold = [skuFontBold].concat(commonFontFamily).join(',');
export const skuFontFamilyMedium = [skuFontMedium].concat(commonFontFamily).join(',');
