import {
    Row, Col, Button, IconButton,
    XIcon, ArrowIcon,
    Popup, PopupHeader,
    ShowPopup,
} from '@commonsku/styles';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { createLoadAddressList, createAddAddress } from '../../actions/address';
import { createAddContact, createLoadCompanyContactList } from '../../actions/contact';
import useWindowSize from '../../hooks/useWindowSize';
import CreateContactForm from '../contact/CreateContactForm';
import CreateAddressForm from '../address/CreateAddressForm';
import NewClientForm from './NewClientForm';
import NewProjectOrderForm from './NewProjectOrderForm';
import StageDetailsForm from './StageDetailsForm';
import { oauth } from '../../utils';
import { window } from '../../global';
import { useIdentity } from '../../hooks';
import { getClients } from '../../selectors/clients';
import { getUsers } from '../../selectors/users';
import { getAddressesList } from '../../selectors/addresses';
import { getCompanyContacts } from '../../selectors/contacts';
import { createLoadEventTypes, createLoadIndustries } from '../../actions';
import { createLoadClientList } from '../../actions/client';
import AsyncClientSelect from './AsyncClientSelect';
import { BASE_ZINDEX } from '../../popup-factory';

const steps = {
    '0': 'SELECT_CLIENT',
    '1': 'CREATE_CLIENT',
    '2': 'CREATE_PROJ',
    '3': 'STAGE_DETAILS',
    '3.1': 'CREATE_BILLING_CONTACT',
    '3.2': 'CREATE_SHIPPING_CONTACT',
    '3.3': 'CREATE_BILLING_ADDRESS',
    '3.4': 'CREATE_SHIPPING_ADDRESS',
};

export default function NewProjectPopup({
    onClose,
  client_id,
  billing_contact_id,
    ...props
}) {
    const identity = useIdentity();
    const dispatch = useDispatch();
    const windowSize = useWindowSize();
    const users = useSelector(getUsers);
    const clients = useSelector(getClients);
    const addresses = useSelector(getAddressesList);
    const company_contacts = useSelector(getCompanyContacts);
    const clientsOptions = useSelector(state => state.dropdowns.clients);

    const [step, setStep] = useState(0);
    const [form, setForm] = useState({
        client_id: client_id || '',
        job_name: '',
        event_type_id: '',
        stage: 'OPPORTUNITY',
        collaborate_suppliers: false,
        collaborate_supplier_id: '',
        collaborate_supplier_message: '',
        billing_contact_id: billing_contact_id || '',
        billing_address_id: '',
        shipping_contact_id: '',
        shipping_address_id: '',
        total_budget: '',
        date_inhandsdate: '',
        at_mention: '',
        note: '',
        view_type: 'TILE',
        sameAsBilling: false,
    });

    function setField(field, value) {
        setForm(
            s => ({ ...s, [field]: value })
        );
    }

    const updateForm = useCallback((data) => {
        if (typeof data === 'function') {
            setForm(
                s => ({ ...s, ...data(s) })
            );
            return;
        }
        setForm(
            s => ({ ...s, ...data })
        );
    }, []);

    const showBackButton = step > 2 || step === 1;
    const onClickGoBack = useCallback(() => {
        setStep(s => {
            if (s === 1) { return 2; }
            if (s === 2) { return 0; }
            const splitFloat = (s + '').split('.');
            if (splitFloat.length > 1) {
                return splitFloat[0];
            }
            return s - 1;
        });
    }, []);

    const handleSave = useCallback((formData) => {
        const stage = formData['stage'];
        if (stage) {
            formData['order_type'] = stage;
            delete formData['stage'];
        }
        ignoreFields(stage).forEach(f => {
            delete formData[f];
        });

        if (![
            'OPPORTUNITY',
            'PRESENTATION',
            'ESTIMATE',
            'SALES ORDER'
        ].includes(stage)) {
            return;
        }

        if (formData.date_inhandsdate) {
            formData.date_inhandsdate = moment(formData.date_inhandsdate).format('YYYY-MM-DD');
        }

        return oauth('POST', 'order', formData).then(
            ({ json }) => {
                const user_id = identity.user_id;
                const order = json.order;
                const jobId = order.job_id;
                const mentionUser = formData.at_mention ? users[formData.at_mention] : null;
                redirectPage({ stage, order, formData, jobId, user_id, mentionUser });
                onClose && onClose();
                return json;
            }
        );
    }, [
        identity.user_id,
        onClose,
        users,
    ]);

    const onClickNewClient = useCallback(() => setStep(1), []);
    const onChangeClient = useCallback(e => setField('client_id', e?.value ?? ''), []);

    const renderStepForm = useCallback(() => {
        if (steps[step] === 'CREATE_CLIENT') {
            return <NewClientForm
                onCreate={(client_id) => {
                    if (!client_id) { return; }
                    setField('client_id', client_id);
                    setStep(2);
                }}
            />;
        } else if (steps[step] === 'CREATE_PROJ') {
            return <NewProjectOrderForm
                form={form}
                updateField={setField}
                onClickNewClient={onClickNewClient}
                isDisabled={!!client_id}
                onContinue={(data) => {
                    updateForm(data);
                    setStep(3);
                }}
            />;
        } else if (steps[step] === 'STAGE_DETAILS') {
            return <StageDetailsForm
                form={form}
                disableBillingContactSelect={!!billing_contact_id}
                updateField={setField}
                updateForm={updateForm}
                onShowNewContact={(type) => {
                    if (type === 'SHIPPING') {
                        setStep(3.2);
                    } else {
                        setStep(3.1);
                    }
                }}
                onShowNewAddress={(type) => {
                    if (type === 'SHIPPING') {
                        setStep(3.4);
                    } else {
                        setStep(3.3);
                    }
                }}
                onSave={(data) => {
                    updateForm(data);
                    return handleSave({ ...form, ...data });
                }}
            />;
        } else if (steps[step] === 'CREATE_BILLING_CONTACT' || steps[step] === 'CREATE_SHIPPING_CONTACT') {
            return <CreateContact
                parent_id={form.client_id}
                parent_type={'CLIENT'}
                contact_type={
                    steps[step] === 'CREATE_BILLING_CONTACT'
                        ? 'BILLING' : 'SHIPPING'
                }
                onCreate={(contact) => {
                    const contact_id = contact.contact_id;
                    if (steps[step] === 'CREATE_SHIPPING_CONTACT') {
                        setField('shipping_contact_id', contact_id);
                    } else {
                        setField('billing_contact_id', contact_id);
                    }
                    onClickGoBack();
                }}
            />;
        } else if (steps[step] === 'CREATE_BILLING_ADDRESS' || steps[step] === 'CREATE_SHIPPING_ADDRESS') {
            return <CreateAddress
                parent_id={form.client_id}
                parent_type={'CLIENT'}
                address_type={
                    steps[step] === 'CREATE_BILLING_ADDRESS'
                        ? 'BILLING' : 'SHIPPING'
                }
                onCreate={(address) => {
                    const address_id = address.address_id;
                    if (steps[step] === 'CREATE_SHIPPING_ADDRESS') {
                        setField('shipping_address_id', address_id);
                    } else {
                        setField('billing_address_id', address_id);
                    }
                    onClickGoBack();
                }}
            />;
        }

        // Popup size is 80% window size with max of 700px
        // 32px popup padding, 2px popup border, 54px popup header, 30px popup content padding
        const maxAvailableHeight = Math.min(windowSize[1] * 0.85, 700) - 32 - 2 - 54 - 30;
        return (
            <Row>
                <Col xs>
                    <AsyncClientSelect
                        defaultMenuIsOpen={true}
                        onClickNewClient={onClickNewClient}
                        value={form.client_id}
                        onChange={onChangeClient}
                        maxAvailableHeight={maxAvailableHeight}
                        autoFocus={true}
                    />
                </Col>
            </Row>
        );
    }, [
        form,
        step,
        updateForm,
        windowSize,
        onClickGoBack,
        onClickNewClient,
        handleSave,
        onChangeClient,
    ]);

    // reset contact and address ids
    // if not client_id set to given/empty ids
    // check if state has correct selected client's contact and address ids
    // if not set given/empty ids
    const resetContactAddress = useCallback(
        (clientId, defaultContactId = '', defaultAddressId='', defaultAddressType='') => {
            if (!!billing_contact_id) {
                // if a billing_contact_id was passed from the contact page, do not reset
                return;
            }

            if (!clientId) {
                setForm(s => ({
                    ...s,
                    billing_contact_id: '',
                    billing_address_id: '',
                    shipping_contact_id: '',
                    shipping_address_id: '',
                }));
                return;
            }

            const clientAddresses = _.filter(
                addresses,
                a => a.parent_type === 'CLIENT'
                    && a.parent_id === clientId
            );
            const contacts = _.get(company_contacts, [clientId], []);

            setForm(s => {
                let billing_contact_id = s.billing_contact_id;
                let billing_address_id = s.billing_address_id;
                let shipping_contact_id = s.shipping_contact_id;
                let shipping_address_id = s.shipping_address_id;

                const foundBillCon = _.find(
                    contacts,
                    c => c.contact_id === s.billing_contact_id
                );
                const foundShipCon = _.find(
                    contacts,
                    c => c.contact_id === s.shipping_contact_id
                );
                const foundBillAddr = _.find(
                    clientAddresses,
                    a => ['BOTH', 'BILLING'].includes(a.address_type)
                        && a.address_id === s.billing_address_id
                );
                const foundShipAddr = _.find(
                    clientAddresses,
                    a => ['BOTH', 'SHIPPING'].includes(a.address_type)
                        && a.address_id === s.shipping_address_id
                );

                const isDefaultBillAddr = !defaultAddressType || ['BOTH', 'BILLING'].includes(defaultAddressType);
                const isDefaultShipAddr = !defaultAddressType || ['BOTH', 'SHIPPING'].includes(defaultAddressType);

                if (!billing_contact_id || (billing_contact_id && !foundBillCon)) {
                    billing_contact_id = defaultContactId;
                }
                if (!shipping_contact_id || (shipping_contact_id && !foundShipCon)) {
                    shipping_contact_id = defaultContactId;
                }
                if (!billing_address_id || (billing_address_id && !foundBillAddr)) {
                    billing_address_id = isDefaultBillAddr ? defaultAddressId : '';
                }
                if (!shipping_address_id || (shipping_address_id && !foundShipAddr)) {
                    shipping_address_id = isDefaultShipAddr ? defaultAddressId : '';
                }

                return {
                    ...s,
                    billing_contact_id: billing_contact_id,
                    billing_address_id: !['OPPORTUNITY', 'PRESENTATION'].includes(s.stage)
                        ? billing_address_id : '',
                    shipping_contact_id: !['OPPORTUNITY', 'PRESENTATION'].includes(s.stage)
                        ? shipping_contact_id : '',
                    shipping_address_id: !['OPPORTUNITY', 'PRESENTATION'].includes(s.stage)
                        ? shipping_address_id : '',
                };
            });
        },
        [company_contacts, addresses, billing_contact_id]
    );

    useEffect( () => {
        const getDefaultPresentationStyle = async () => {
            const company_id = identity.company_id;
            const { json } = await oauth('GET', `company-data/${company_id}`,  {});
            const { company_data } = json;
            return company_data.default_presentation_style;
        };

        let ignore = false;
        if (steps[step] === 'STAGE_DETAILS' && form.stage === 'PRESENTATION') {
            getDefaultPresentationStyle().then(s => !ignore && setField('view_type', s || 'LIST'));
        }
        return () => { ignore = true; };
    }, [form.stage, identity.company_id, identity.company_type, step]);

    useEffect(() => {
        (async () => {
            await Promise.all([
                dispatch(createLoadEventTypes()),
                dispatch(createLoadIndustries()),
              (_.isEmpty(clients) && _.isEmpty(clientsOptions)) && dispatch(createLoadClientList(client_id ? { client_id } : {'max-results': 30})),
            ]);
        })();
    }, []);

    // load relavent contacts and adresses for client
    useEffect(() => {
        if (form.client_id) {
            setStep(
                s => s === 0 || s === 1 ? 2 : s
            );

            dispatch(createLoadCompanyContactList(form.client_id, 'CLIENT'));
            dispatch(createLoadAddressList(form.client_id, 'CLIENT'));
        }
    }, [form.client_id, dispatch]);

    // on client change
    // update contact and addresses
    useEffect(() => {
        if (!form.client_id) {
            resetContactAddress(form.client_id);
            return;
        }

        const primary_contact_id = _.get(clients, [form.client_id, 'primary_contact_id'], null);
        if (!primary_contact_id) {
            resetContactAddress(form.client_id);
            return;
        }
        const contact = _.get(company_contacts, [form.client_id], []).filter(c => c.contact_id === primary_contact_id);
        if (!contact) {
            resetContactAddress(form.client_id);
            return;
        }

        let address = null;
        const clientAddresses = _.filter(
            addresses,
            a => a.parent_type === 'CLIENT'
                && a.parent_id === form.client_id
        );
        if (contact.contact_default_address_id) {
            address = _.find(
                clientAddresses,
                a => a.address_id === contact.contact_default_address_id
            );
        }

        const address_id = _.get(address, ['address_id'], '');
        const address_type = _.get(address, ['address_type'], '');
        resetContactAddress(form.client_id, primary_contact_id, address_id, address_type);
    }, [form.client_id, form.stage, clients, company_contacts, addresses, resetContactAddress]);

    // on change billing contact, set shipping contact's primary address
    useEffect(() => {
        if (form.billing_contact_id) {
            setForm(s => {
                const contacts = company_contacts[s.client_id];
                const contact = _.find(contacts, v => v.contact_id === form.billing_contact_id);
                const primary_address_id = _.get(contact, ['contact_default_address_id'], '');
                if (!primary_address_id) {
                    return s;
                }
                const addressFound = _.find(
                    addresses,
                    a => a.parent_type === 'CLIENT'
                        && a.parent_id === s.client_id
                        && a.address_id === primary_address_id
                        && ['BOTH', 'BILLING'].includes(a.address_type)
                );

                return {...s, billing_address_id: _.get(addressFound, ['address_id'], '')};
            });
        }
    }, [form.billing_contact_id, form.stage, company_contacts, addresses]);

    // on change shipping contact, set shipping contact's primary address
    useEffect(() => {
        if (form.shipping_contact_id) {
            setForm(s => {
                const contacts = company_contacts[s.client_id];
                const contact = _.find(contacts, v => v.contact_id === form.shipping_contact_id);
                const primary_address_id = _.get(contact, ['contact_default_address_id'], '');
                if (!primary_address_id) {
                    return s;
                }
                const addressFound = _.find(
                    addresses,
                    a => a.parent_type === 'CLIENT'
                        && a.parent_id === s.client_id
                        && a.address_id === primary_address_id
                        && ['BOTH', 'SHIPPING'].includes(a.address_type)
                );

                return {...s, shipping_address_id: _.get(addressFound, ['address_id'], '')};
            });
        }
    }, [form.shipping_contact_id, form.stage, company_contacts, addresses]);

    return (
      <Popup
        closeOnEsc
        closeOnClickOutside={false}
        header={<PopupHeader className="popup-header" xsStyle="flex-wrap: wrap-reverse;" smStyle="flex-wrap: wrap;">
            <Col style={{textAlign: 'left', alignSelf: 'center', display: 'flex'}} xs={8}>
                {showBackButton ?
                  <IconButton
                    Icon={ArrowIcon}
                    iconProps={{direction: 'left'}}
                    variant="text"
                    onClick={onClickGoBack}
                    px={5}
                    pt={5}
                    pb={10}
                  />
                  : null}
                <span className="title" style={{lineHeight: "54px"}}>New Project</span>
            </Col>
            <Col style={{textAlign: 'right', alignSelf: 'center'}} xs={4}>
                <IconButton
                  variant="secondary"
                  size="medium"
                  onClick={onClose}
                  Icon={<XIcon altText="Close" />}
                />
            </Col>
        </PopupHeader>}
        style={{ maxWidth: 500, height: '85%'}}
        overlayZIndex={BASE_ZINDEX + 100}
        zIndex={BASE_ZINDEX + 200}
      >
          <div style={{ paddingTop: 30, paddingLeft: 5, paddingRight: 5 }}>
              {renderStepForm()}
          </div>
      </Popup>
    );
}

function CreateAddress({
    parent_id,
    parent_type='CLIENT',
    address_type='BILLING',
    onCreate,
}) {
    const dispatch = useDispatch();

    const [addressForm, setAddressForm] = useState({});
    const [addressFormValid, setAddressFormValid] = useState(true);
    const [showAddressError, setShowAddressError] = useState(false);

    function handleCreateAddress() {
        setShowAddressError(true);
        if (!addressFormValid) { return; }

        try {
            dispatch(createAddAddress(addressForm))
            .then(action => {
                onCreate && onCreate(action.payload.address);
            });
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Row>
            <CreateAddressForm
                parent_id={parent_id}
                parent_type={parent_type}
                address_type={address_type}
                showError={showAddressError}
                setFormValid={setAddressFormValid}
                setForm={setAddressForm}
            />
            <Col xs pb={10} style={{ textAlign: "center" }} padded>
                <Button style={{ width: '100%' }} variant="primary" onClick={handleCreateAddress}>
                    Create
                </Button>
            </Col>
        </Row>
    );
}

function CreateContact({
    parent_id,
    parent_type='CLIENT',
    contact_type='BILLING',
    onCreate,
}) {
    const dispatch = useDispatch();

    const [contactForm, setContactForm] = useState({});
    const [contactFormValid, setContactFormValid] = useState(true);
    const [showContactError, setShowContactError] = useState(false);

    function handleCreateContact() {
        setShowContactError(true);
        if (!contactFormValid) { return; }

        try {
            dispatch(createAddContact({
                ...contactForm,
                contact_tags: _.get(contactForm, ['contact_tags'], []).join(','),
            }))
            .then(action => {
                onCreate && onCreate(action.payload.contact);
            });
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Row>
            <CreateContactForm
                parent_id={parent_id}
                parent_type={parent_type}
                contact_type={contact_type}
                showError={showContactError}
                setFormValid={setContactFormValid}
                setForm={setContactForm}
                smallerScreenSize
            />
            <Col xs pb={10} style={{ textAlign: "center" }} padded>
                <Button style={{ width: '100%' }} variant="primary" onClick={handleCreateContact}>
                    Create
                </Button>
            </Col>
        </Row>
    );
}

function ignoreFields(stage) {
    let fields = [
        'collaborate_suppliers',
        'collaborate_supplier_id',
        'collaborate_supplier_message',
        'sameAsBilling',
    ];
    if (stage === 'OPPORTUNITY' || stage === 'PRESENTATION') {
        fields = fields.concat([
            'billing_address_id',
            'shipping_contact_id',
            'shipping_address_id',
        ]);
    } else if (stage === 'SALES ORDER') {
        fields = fields.concat([
            'total_budget',
        ]);
    }

    if (stage !== 'OPPORTUNITY') {
        fields = fields.concat([
            'at_mention',
            'note',
        ]);
    }
    if (stage !== 'PRESENTATION') {
        fields = fields.concat([
            'view_type',
        ]);
    }

    return fields;
}

function redirectPage({ stage, order, formData, jobId, user_id, mentionUser }) {
    const projectLink = `/project/${order.job_number}`;
    if (stage === 'OPPORTUNITY') {
        if (formData.at_mention && mentionUser) {
            const noteData = {
                date_reminder: '',
                details_parent_id: jobId,
                details_parent_type: 'JOB',
                details_type: 'NOTE',
                job_id: jobId,
                message_text: `${formData.note ? (formData.note + '\n') : ''}@${mentionUser.mask}`,
                reminder_user: user_id,
                tagged_users: formData.at_mention,
            };
            oauth('POST', 'message', noteData).then(() => {
                setTimeout(() => {
                  window.open(projectLink, '_blank');
                });
            });
            return;
        }

        setTimeout(() => {
          window.open(projectLink, '_blank');
        });
        return;
    }

    const subRoute = stage.toLowerCase().split(' ').join('-');
    setTimeout(() => {
      window.open(`${projectLink}/${subRoute}/${order.form_number}/add-product`, '_blank');
    });
}

export const NewProjectPopupButton = ({style, isButton=true, buttonText='New Project', className=null, buttonProps={}, ...props}) => {
    return (
      <ShowPopup
        {...props}
        popup={NewProjectPopup}
        autoOpen={false}
        render={({ onClick }) => {
            const handleClick = (e) => {
                onClick();
            };

            if (!isButton) {
                return (<div className={className} onClick={handleClick} style={style}>{buttonText}</div>);
            }
            return (
              <Button className={className} variant="primary" onClick={handleClick} style={style} {...buttonProps}>{buttonText}</Button>
            );
        }}
      />
    );
};
