import React, { Component } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import ProjectMarginSummary from './ProjectMarginSummary';

import { createAddCallout } from '../actions/callout';
import { createLoadOrder } from '../actions/order';
import { updatePurchaseOrder } from '../actions/purchase_order';
import { getBillingBySalesOrder } from '../selectors';
import { formatMoneyCurrency, formatMoney, isNumeric, oauth } from '../utils';
import { createAddTemp } from '../actions/temp';

const BillRow = ({ bill, project_number, order_currency, canCreateBill, onUpdate }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onCreateBill = purchase_order => {
    const data = { purchase_order_id: purchase_order.purchase_order_id, unbilled: true };
    const request = oauth('GET', 'purchase-order-item', data).then(({ json }) => {
      const bill = {
        division_id: purchase_order.division_id,
        supplier_id: purchase_order.division_id,
        purchase_orders: [ Object.assign({}, purchase_order, { items: json.purchase_order_items }) ]
      };
      dispatch(createAddTemp(purchase_order.purchase_order_id, bill));
      navigate(`/project/${project_number}/bills/add-bill?purchase_order_id=${purchase_order.purchase_order_id}`);
    }, ({ json }) => {
      dispatch(createAddCallout('error', 'Unable to create bill from purchase order', 'error'));
    });
  };

  const hasPO = !!bill.purchase_order_id;
  const hasBill = bill.bill_reference_number !== null;
  const addBill = !hasBill && canCreateBill && 0 == bill.billed;

  const currency = bill.bill_currency !== order_currency ? <>{bill.bill_currency}&nbsp;</> : '';
  const bill_subtotal = formatMoneyCurrency(bill.bill_subtotal, bill.bill_currency);
  const bill_item_subtotal = formatMoneyCurrency(bill.bill_item_subtotal, bill.bill_currency);

  return (
    <tr>
      <td>{hasPO && <a target="_blank" href={`/purchase_order.php?id=${bill.purchase_order_id}`}>{bill.form_number}</a>}</td>
      <td>{bill.po_status_name ? <span style={{ padding: '0.4rem' }} className={'status-badge ' + bill.po_status_name.replace(/ /g, '-').toLowerCase()}>{bill.po_status_name}</span> : <span>&nbsp;</span>}</td>
      <td>{hasPO ? <a target="_blank" href={`/vendor.php?id=${bill.po_company_id}&division_id=${bill.po_division_id}`}>{bill.po_division_name}</a> : <span>&nbsp;</span>}</td>
      <td>{isNumeric(bill.total) ? '$' + formatMoney(bill.total) : <span>&nbsp;</span>}</td>
      <td>{hasBill ? <Link to={`/project/${project_number}/bills/${bill.bill_id}`}>{bill.bill_reference_number}</Link> : (addBill ? <a onClick={e => { e.preventDefault(); onCreateBill(bill); }}>+ Add Bill</a> : null)}</td>
      <td>{hasBill ? <a target="_blank" href={`/vendor.php?id=${bill.bill_company_id}&division_id=${bill.bill_division_id}`}>{bill.bill_division_name}</a> : <span>&nbsp;</span>}</td>
      <td style={{ textAlign: 'center', width: '1%' }}>{hasPO && (onUpdate ? <input type="checkbox" checked={1 == bill.billed} onChange={e => onUpdate(bill.purchase_order_id, 'billed', bill.billed)(1 == bill.billed ? 0 : 1)} /> : (1 == bill.billed ? 'Yes' : 'No'))}</td>
      <td>{bill.bill_subtotal !== null ?
        <>
         {currency} {bill_subtotal}
         {bill_subtotal !== bill_item_subtotal && <>
           <br />({currency} {bill_item_subtotal} on project)
          </>}
       </> : <span>&nbsp;</span>}</td>
    </tr>
  );
};

class ProjectBilling extends Component {

  constructor(props) {
    super(props);

    this.hasCapability = this.hasCapability.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (!this.props.project.loaded) {
      this.props.project.sales_orders.forEach(o => {
        this.props.loadOrder(o.order_id);
      });
      this.props.project.invoices.forEach(o => {
        this.props.loadOrder(o.order_id);
      });
    }
  }

  hasCapability(capability) {
    return this.props.identity.capabilities.includes(capability);
  }

  renderLoading() {
    return (
      <div className="main-content project-order" style={{ textAlign: 'center'}} >
        <img style={{ marginTop: '2rem' }} src="/images/gears.gif" />
      </div>
    );
  }

  render() {
    const { project } = this.props;

    if (!project.loaded) {
      return this.renderLoading();
    }

    const { bills_by_sales_order, onUpdatePurchaseOrder } = this.props;
    const canCreateBill = this.hasCapability('CREATE-BILL');
    const canUpdatePurchaseOrder = this.hasCapability('MODIFY-PURCHASE-ORDER');
    return (
      <div className="main-content">
      {bills_by_sales_order.map((order, i) => {
        const subtotals = Object.values(order.bills.filter(b => !!b.bill_id).reduce(
          (x, b) => ({
            ...x,
            [b.bill_currency]: {
              ...x[b.bill_currency],
              currency: b.bill_currency,
              exchange_rate: b.bill_exchange_rate,
              bill_subtotal: (x[b.bill_currency]?.bill_subtotal ?? 0) + Number(b.bill_subtotal),
              bill_item_subtotal: (x[b.bill_currency]?.bill_item_subtotal ?? 0) + Number(b.bill_item_subtotal),
            }
          }),
          {}
        ));

        return (
          <div key={i}>
            <div className="small-12 columns">
              <h3>For Sales Order #{order.form_number}</h3>
            </div>
            <div className="small-12 columns">
              <table id="bills-table">
                <thead>
                  <tr>
                    <th>PO #</th>
                    <th>PO Status</th>
                    <th>PO Supplier</th>
                    <th>PO Amount</th>
                    <th>Bill #</th>
                    <th>Bill Supplier</th>
                    <th>All Bills Received</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                {order.bills.map((b, index) =>
                  <BillRow key={index} bill={b} order_currency={order.currency_id} canCreateBill={canCreateBill} project_number={project.job_number} onUpdate={canUpdatePurchaseOrder ? onUpdatePurchaseOrder : null} />
                )}
                </tbody>
              </table>
            </div>
            <ProjectMarginSummary project={project} order_id={order.order_id} force_subtotal={true} />
            <div className="large-4 columns">&nbsp;</div>
            <div className="large-4 columns">
              <strong>Bill Subtotal:</strong>
              {subtotals.map(
                subtotal => (
                  <p key={subtotal.currency}>
                    {subtotal.currency !== order.currency_id && <>{subtotal.currency}&nbsp;</>}
                    {formatMoneyCurrency(subtotal.bill_subtotal, subtotal.currency)}
                    {subtotal.bill_subtotal !== subtotal.bill_item_subtotal && (
                      <>
                        <br />
                        ({subtotal.currency !== order.currency_id && <>{subtotal.currency}&nbsp;</>}
                        {formatMoneyCurrency(subtotal.bill_item_subtotal, subtotal.currency)} on project)
                      </>
                    )}
                  </p>
                )
              )}
            </div>
          </div>
        );
      })}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const loaded = ownProps.project.sales_orders.reduce((t, o) => t && o.loaded, true) && ownProps.project.invoices.reduce((t, o) => t && o.loaded, true);
  const project = loaded ? Object.assign({ loaded }, ownProps.project) :
    ownProps.project.sales_orders[0].loaded ? Object.assign(ownProps.project, {
      loaded: true,
      margin_data: {
        [ownProps.project.sales_orders[0].order_id]: ownProps.project.sales_orders[0].margin_data
      }
    }) : Object.assign({ loaded: false }, ownProps.project);
  const bills_by_sales_order = getBillingBySalesOrder(state);
  return {
    project,
    bills_by_sales_order,
    identity: state.identity
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadOrder: order_id => dispatch(createLoadOrder(order_id)),
    onUpdatePurchaseOrder: (purchase_order_id, field, previous_value) => value => {
      dispatch(updatePurchaseOrder(purchase_order_id, field, previous_value, value));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectBilling);
